<template>
    <div class="mt-5 px-xl-5">
        <b-row class="mb-2 justify-content-center">
            <b-col cols="8">
                <h6 class="page-title pb-2 text-uppercase">
                    {{ $t("add-competent-authority.title") }}
                </h6>
            </b-col>
        </b-row>
        <b-row class="mb-5 justify-content-center">
            <b-col cols="8">
                <b-card
                    class="shadow-sm border-0 rounded-0 px-md-4"
                    bg-variant="light"
                    no-header
                >
                    <b-card-text>
                        <b-row>
                            <b-col md="6" class="pe-md-5 pb-3">
                                <b-form-group>
                                    <label class="form-title">{{
                                        $t("general.name")
                                    }}</label>
                                    <b-form-input
                                        v-model="registryForm.name"
                                        size="sm"
                                        class="rounded-0"
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col class="text-end">
                                <b-overlay
                                    :show="sending"
                                    rounded
                                    opacity="0.7"
                                    spinner-small
                                    spinner-variant="primary"
                                    class="d-inline-block"
                                >
                                    <b-button
                                        class="main-btn rounded px-4"
                                        @click="registerCompetentAuthority"
                                        :disabled="!canSave"
                                        >{{ $t("general.save") }}</b-button
                                    >
                                </b-overlay>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "add-competent-authority",
    data() {
        return {
            registryForm: {
                name: "",
            },
            sending: false,
        };
    },
    mounted() {
        if (!this.allowCreateCA) {
            return this.$router.push({
                name: "competent-authorities",
            });
        }
    },
    computed: {
        ...mapGetters("application", ["allowSuperintendenceCreateCA"]),
        allowCreateCA() {
            return this.allowSuperintendenceCreateCA;
        },
        canSave() {
            let form = this.registryForm;
            let valid = form.name !== "";
            return valid;
        },
    },
    methods: {
        ...mapActions("modals", ["openModal", "setModalInfo"]),
        async registerCompetentAuthority() {
            this.sending = true;
            try {
                let request = {
                    competentAuthority: {
                        name: this.registryForm.name,
                    },
                };
                await RepositoryFactory.competentAuthority.add(request);
                this.setSuccesInfo();
            } catch {
                this.setErrorInfo();
            }
            this.openModal();
            this.sending = false;
        },
        setSuccesInfo() {
            this.setModalInfo({
                title: this.$t("add-competent-authority.success.title"),
                message: this.$t("add-competent-authority.success.message"),
                routeName: "competent-authorities",
            });
        },
        setErrorInfo() {
            this.setModalInfo({
                title: this.$t("add-competent-authority.error.title"),
                message: this.$t("add-competent-authority.error.message"),
                subMessage: this.$t("add-competent-authority.error.subMessage"),
            });
        },
    },
};
</script>